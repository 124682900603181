.document_page {
    &_container {

        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.5rem 0rem 1rem;
            &_title {
                font: var(--header6-semi-bold);
                color: var(--content-neutral-dark);
            }
        }

        .e-rte-container,
        .tribute-demo-input {
            border: 0px !important;
        }

        .e-control.e-richtexteditor .e-rte-content .e-content {
            padding: 1rem;
        }
    }

    &_header_input {
        & .e-input-group {
            border: 0 !important;
            height: auto;
            min-height: unset !important;
            max-height: unset;

            &.e-disabled {
                background-color: transparent !important;
                border: 0px;
                color: var(--content-neutral-dark) !important;
            }

            &.e-input-focus::before {
                height: 2.5rem;
                min-height: 2.5rem;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                top: -6px;
                box-shadow: 0 0 0 1px var(--stroke-primary);
                border-radius: 0.5rem;
            }

            & input.e-input {
                height: auto;
                min-height: unset;
                max-height: unset;
                font-size: 1.25rem;
                line-height: 1.75rem !important;
                color: var(--content-neutral-dark);
                font-weight: 600 !important;
                font-family: "Montserrat" !important;
                padding-left: 1rem !important;

                &[disabled] {
                    -webkit-text-fill-color: var(--content-neutral-dark);
                    color: var(--content-neutral-dark);
                    opacity: 1;
                }
            }

            .e-input[disabled],
            .e-input-group .e-input[disabled],
            .e-input-group.e-control-wrapper .e-input[disabled],
            .e-input-group.e-disabled,
            .e-input-group.e-control-wrapper.e-disabled,
            .e-float-input input[disabled],
            .e-float-input.e-control-wrapper input[disabled],
            .e-float-input textarea[disabled],
            .e-float-input.e-control-wrapper textarea[disabled],
            .e-float-input.e-disabled,
            .e-float-input.e-control-wrapper.e-disabled {
                -webkit-text-fill-color: var(--content-neutral-dark);
            }
        }
    }

    &_right_content_header_avatar {
        width: 1.5rem;
        height: 1.5rem;
        min-height: 1.5rem;
        min-width: 1.5rem;
        border: 1px solid var(--stroke-neutral-base);
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.web_page_table {
    width: 100%;
    border: 1px solid var(--stroke-neutral-base);

    td {
        padding: 0.5rem 1rem;
        border-bottom: 1px solid var(--stroke-neutral-base);
        cursor: pointer;
    }
}