.my-preview-container {
    margin-top: 2em;
    max-width: calc(100% - 64px);
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.btn {
    appearance: none;
    border: 1px solid lightgray;
    cursor: pointer;
    padding: 4px 8px;
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.button-container {
    width: 100%;
    display: flex;
    gap: 8px;
}

.btn--choose {
    background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
    overflow: hidden;
    flex: 1 1 auto;
}

.btn--choose:hover {
    background-color: var(--sjs-primary-backcolor-dark, rgb(20, 164, 139));
}

.btn--remove {
    background-color: var(--sjs-special-red, var(--red, #e60a3e));
}

.btn--remove:hover {
    background-color: rgb(217, 4, 49);
}
  