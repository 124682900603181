.rafPageBuilderBody {
    display: flex;
    flex-direction: row;
}

.rafPageBuilderBody .customDropdownBtn {
    width: 100%;
}

/* 
    .rafPageBuilderBody .sideBar {
        flex: 0 0 1;
        width: 200px;
        /*padding: 10px;*/
/*height: 100vh;*/
/*background: #e1e1e1;*/
/* background: #fff;
        position: fixed;
        bottom: 20px;
        top: 105px;
        overflow: auto;
        left: calc(220px + 1.5rem);
    } */

.rafPageBuilderBody .sideBarRight {
    flex: 0 0 1;
    width: 200px;
    padding: 10px;
    height: 100vh;
    background: #fff;
}

.rafPageBuilderBody .sideBarItem {
    /*border: 1px solid #000;*/
    border: 1px solid #666;
    border-radius: 3px;
    height: 30px;
    /*padding: 10px;*/
    padding: 0 10px;
    margin-top: 10px;
    background: #fff;
    /*background: #ccc;*/
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #333;
    font-weight: 500;
}

.rafPageBuilderBody .page {
    flex: 1 1 auto;
    padding: 0 20px;
    margin: 0 0px 20px 20px;
    /*border: 1px solid green;*/
    background-color: #fff;
}

.rafPageBuilderBody .dropZone {
    /*display: none;*/
    flex: 0 0 auto;
    /*height: 40px;*/
    height: 15px;
    transition: 200ms all;
    /*border: dashed 1px gray;*/
}

/*.rafPageBuilderBody .component.draggable:hover ~ .dropZone {
        display: flex;
    }*/

/*.rafPageBuilderBody .dropZone:nth-of-type(2n) {
            display: none;
        }*/

.rafPageBuilderBody .dropZone.horizontalDrag {
    width: 20px;
    height: auto;
    border: 1px dashed #eaeaea;
}

.rafPageBuilderBody .dropZone:not(.horizontalDrag).isLast {
    flex: 1 1 auto;
}

.rafPageBuilderBody .dropZone:not(.horizontalDrag).isLast:first-child {
    border: 1px dashed #eaeaea;
    margin: 20px 0;
    height: 100px;
}

.rafPageBuilderBody .dropZone.active,
.trashDropZone.active {
    /*background: #00a2ff;*/
    /*background: #eaeaea;*/
    background: #d9edf7;
    transition: 100ms all;
}


.rafPageBuilderBody .component {
    /*height: 60px;*/
    padding: 10px;
    background: #aaa;
    border: 1px solid transparent;
}

.rafPageBuilderBody .component.active {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.rafPageBuilderBody .component .form-group {
    margin-bottom: 0px;
}

.rafPageBuilderBody .component .form-label {
    margin: 0px !important;
}

.rafPageBuilderBody .component .settingsDiv .form-group {
    margin-bottom: 15px !important;
}

.rafPageBuilderBody .component .settingsDiv .form-label {
    margin-bottom: 10px !important;
}

.rafPageBuilderBody .component .e-input-group.border-0 {
    border: 1px solid transparent !important;
}

.rafPageBuilderBody .component .e-input-group.border-0:hover {
    border: 1px solid #767676 !important;
}

.rafPageBuilderBody .component .e-input-group.border-0.e-input-focus {
    border: 1px solid #0078d6 !important;
}

.rafPageBuilderBody .rafPageBuildercolumn {
    /*border: 1px solid blue;*/
    flex: 1 1 100%;
    padding: 10px;
}

.rafPageBuilderBody .rafPageBuildercolumns {
    display: flex;
    padding: 20px 0;
}

.rafPageBuilderBody .columnContainer {
    display: flex;
}

.rafPageBuilderBody .base {
    padding: 0.5rem 1rem;
    background-color: white;
    cursor: move;
}

.rafPageBuilderHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px -20px 0 -20px;
    padding: 5px 1rem 5px 1rem;
    border: 1px solid #eaeaea;
    background: #fff;
    border-left: 0;
    border-right: 0;
}

.rafPageBuilderHeader .form-group {
    margin-bottom: 0;
}

.rafPageBuilderHeader .e-input-group {
    border-color: transparent !important;
}

.rafPageBuilderHeader .e-input-group.e-input-focus {
    border-color: #0078d6 !important;
}

.rafPageBuilderBody .base .base {
    border-top: 1px dashed #eaeaea;
    border-bottom: 1px dashed #eaeaea;
}

.rafPageBuilderBody .rafPageBuilderrow {
    /*border: 1px solid red;*/
    padding: 0;
}

.rafPageBuilderBody rafPageBuilderpanel {
    border: 1px solid black;
    padding: 0;
}

.rafPageBuilderBody .trashDropZone {
    position: relative;
    text-align: center;
    padding: 20px 0;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 1px solid purple;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rafPageBuilderBody .pageContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0px 0 20px 200px;
}

.draggable-settings-btn-outer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rafPageBuilderBody .component .draggable-settings-btn-outer {
    visibility: hidden;
}

.rafPageBuilderBody .component:hover .draggable-settings-btn-outer {
    visibility: visible;
}

.draggable-settings-btn-outer button {
    margin: 5px;
}

.draggable-settings-btn-outer button:last-child {
    margin-right: 0px;
}

.settingsOuter {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    z-index: 1500;
    cursor: auto;
}

.settingsDiv {
    width: 450px;
    height: 100%;
    z-index: 1500;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 1rem 1.5rem;
}

.settingsDiv-footer {
    background-color: #fff;
    padding: 10px 15px;
    border-top: 1px solid #e0e0e0;
    display: flex;
    justify-content: flex-end;
}

.transparentBackdrop.show {
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}

.pageLayout-save {
    position: fixed;
    /*top: 55px;
    right: 16px;*/
    top: 85px;
    right: 5px;
    z-index: 1030;
}

.pageLayout-save.scrolling-navbar.top-nav-collapse {
    top: 35px;
}

.pageLayout-save .grid-toolbar-component .e-toolbar .e-toolbar-items {
    flex: 0 0 auto;
    width: 33.33333333%;
}

.pageLayout-save .grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-items-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageLayout-save .header-text {
    font-size: 1.25rem;
    cursor: text;
    background-color: transparent;
    color: #333 !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item.header-text:hover {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #333 !important;
}

.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item.header-text span,
.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item.header-text button {
    font-size: 1.125rem !important;
    font-weight: 500;
    color: #333 !important;
    margin: 0 !important;
    padding: 0 !important;
}

.multifield-item {
    border: 1px dashed #e3e3e7;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%) !important;
    min-height: 100px;
}

/* .pageContainer .divAttributeRowItem {
    background-color: #f4f5f6;
} */
.sideBar {
    width: 200px;
}

.sideBar .divAttributeRowItem .sideBarItem.disabled {
    opacity: .6;
}

.sideBar .divAttributeRowItem .sideBarItem {
    min-height: 35px;
    background-color: #f3f4f5;
    display: flex;
    align-items: center;
    font-size: 0.75em;
    padding: 1rem;
}

.sideBar .divAttributeRowItem .sideBarItem i {
    font-size: 1.5em;
    color: #333;
}

.sideBar .divAttributeRowItem .sideBarItem .sidebarItemIcon {
    visibility: hidden;
}

.sideBar .divAttributeRowItem .sideBarItem:hover .sidebarItemIcon {
    visibility: visible;
}

.pageContainer .divAttributeRowItem.drop-zone {
    background-color: #d5e6fb;
    /*background-color: #e4f8ed;*/
    /*background-color: #dce1e9;*/
}

.pageContainer .divAttributeRowItem .sideBarItemOuter {
    /* display: flex; */
    align-items: center;
    margin-top: 0.5rem;
    border: 1px solid #666;
}

/* 
.pageContainer .divAttributeRowItem div:first-child .sideBarItemOuter {
    margin-top: 0;
} */

.pageContainer .divAttributeRowItem .sideBarItem {
    width: 100%;
    min-height: 32px;
    margin: 0;
    border: 0;
    border-right: 1px solid #666;
    border-radius: 0;
}

.pageContainer .divAttributeRowItem .sideBarItem .sideBarItemText {
    vertical-align: middle;
    line-height: 2.375;
}

.pageContainer .divAttributeRowItem .sideBarItem+button {
    border-right: 1px solid #666;
}

.divAttributeRowItem:empty {
    display: flex;
    align-items: center;
    justify-content: center;
}

.divAttributeRowItem:empty:before {
    content: attr(data-placeholder);
}

.e-entity:before {
    content: "\e310";
}

.e-entity-text:before {
    content: "\e198";
}

.e-entity-multiline:before {
    content: "\e273";
}

.e-entity-number:before {
    content: "\e503";
}

.e-entity-dropdown:before {
    content: "\e90e";
}

.e-entity-date:before {
    content: "\e93a";
}

.e-entity-lookup:before {
    content: "\e19b";
}

.e-entity-boolean:before {
    content: "\e19d";
}

.e-entity-form:before {
    content: "\e19c";
}

.e-entity-radiobutton:before {
    content: "\e90f";
}

.e-entity-multiselect:before {
    content: "\e19d";
}

.e-entity-datalist:before {
    content: "\e199";
}

.e-entity-panel:before {
    content: "\e90c";
}

.e-entity-datetime:before {
    content: "\ea90";
}

.e-entity-password:before {
    content: "\e91a";
}

.e-entity-autonumber:before {
    content: "\e503";
}

.e-entity-formula:before {
    content: "\e717";
}

.e-entity-image:before {
    content: "\e335";
}

.e-entity-video:before {
    content: "\e327";
}

.e-entity-richtexteditor:before {
    content: "\e90a";
}

.e-entity-heading:before {
    content: "\eb03";
}

@media only screen and (max-width: 1150px) {

    .pageLayout-save .grid-toolbar-component .e-toolbar .e-toolbar-items {
        flex: auto;
        width: unset;
    }

    .pageLayout-save .grid-toolbar-component .e-toolbar .e-toolbar-items .e-toolbar-items-center {
        align-items: flex-start;
    }
}

@media only screen and (max-width: 767px) {
    /* .rafPageBuilderBody .sideBar {
        left: 20px;
    } */
}

@media only screen and (max-width: 600px) {
    /* .rafPageBuilderBody .sideBar {
        position: static;
        width: 100%;
    } */

    .rafPageBuilderBody {
        flex-direction: column;
    }

    .rafPageBuilderBody .pageContainer {
        margin: 20px 0;
    }

    .rafPageBuilderBody .page {
        margin: 0;
    }
}