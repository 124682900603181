.bp-card {

    // & .form-library-item-btn:not(.active),
    // &.e-hover .e-card .form-library-item-btn:not(.active) {
    //     visibility: hidden;
    // }

    // &.e-hover .form-library-item-btn {
    //     visibility: visible;
    // }
}

.rafColumnList {
    &__card.e-card {
        line-height: normal;
        box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.07);
    }
}

.formLibraryTab {
    height: 100%;
    display: flex;
    flex-direction: column;

    & .e-tab-header {
        background-color: #fff;
    }

    & .e-content {
        height: 100%;
        overflow: auto;
        // padding: 1rem;
        border-top: 1px solid #d7d7d7;

        & .e-card {
            //padding: 1rem !important;
        }
    }
}

.overview-header-btn-div {
    width: 140px;
}

.mobileLayout {
    height: 100%;
    overflow: auto;
    position: relative;

    &__img {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index: 1;
        pointer-events: none;
        top: 0px;

        &::after {
            content: "";
            background-image: url(../../../assets/mobileLayout.svg);
            background-repeat: no-repeat;
            height: 875px !important;
            width: 450px !important;
            min-height: 875px !important;
            display: block;
        }
    }

    &__content {
        position: relative;
        width: 354px;
        height: 622px !important;
        transform: scale(1) translate(-50%, 0%);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-sizing: content-box;
        position: absolute;
        transform-origin: 0 0;
        top: 97px;
        left: 50%;
        // transform: scale(1.26) translate(-50%, -50%);
        overflow: auto;
    }
}

.fl-card {
    & .detailsField-value-div>span {
        vertical-align: super;
    }
}

.formLibrary__form {
    width: 100%;
    max-width: 29cm;
    margin: auto;
}

.formPageContainer {
    padding: 1rem;
    // margin: 1rem auto !important;
    // background-color: #fff;
}

.rightDialog.full-height.createEditForm .e-dlg-body-content .formPageContainer {
    padding: 0;
}

.removeformPageContainerPadding .formPageContainer {
    padding: 0;
}

// @media (min-width: 800px) {
//     .formLibrary__form {
//         min-width: 100%;
//     }
// }

.rules-card .e-query-builder {
    background-color: transparent;
    // border: 2px solid rgba(0, 0, 0, 0.03);
    border: 0;
    border-radius: 4px;

    & .e-group-header .e-group-action {
        margin-top: 0;
    }

    & .e-ddl.e-custom-group-btn {
        background-color: transparent;
        border: 1px solid transparent !important;
        color: #0078d6;
        font-size: 1rem;
        font-weight: 500;
        width: auto;
        min-width: none;
        border-radius: 0;
        min-height: unset;
        height: 1.75rem;
        line-height: normal;
        border-bottom: 1px dashed #0078d6 !important;
        width: 45px !important;

        & .e-input {
            height: 24px;
            min-height: 24px;
            font-size: 0.875rem;
            padding: 0;
        }

        & .e-input-group-icon {
            color: #0078d6 !important;
            height: 1.625rem;
            min-height: 1.625rem;

            width: auto;
            min-width: unset;
            // display: none;
        }

    }

    & .e-btn.e-add-btn,
    & .e-removerule.e-btn.e-round {
        background-color: #fff;
    }

    & .e-btn.e-btngroup-and-lbl,
    & .e-btn.e-btngroup-or-lbl {
        background-color: #fff;
    }

    & .e-rule-container {
        background-color: transparent;
        border: 2px solid rgba(0, 0, 0, 0.03);
        border-radius: 4px;

        &.e-prev-joined-rule {
            border-bottom-width: 1px;
        }

        & .e-rule-field {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            & .e-rule-filter {
                order: 1;
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            & .e-rule-value-delete {
                width: auto;
                order: 2;
            }

            & .e-rule-operator {
                width: 100%;
                order: 3;
            }

            & .e-rule-value {
                width: 100% !important;
                order: 4;
            }
        }
    }
}

.e-ddl.e-popup.e-custom-group-btn {
    width: 80px !important;
}