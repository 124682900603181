.e-bigger .e-row-responsive.e-device.rafSFGrid.rafSFTaskGrid .e-gridcontent {
    & tr td:first-child {
        padding: 0.5rem 0.75rem 0.5rem;
        min-height: unset;
    }

    & tr td:nth-child(2) {
        padding: 0.75rem;
        // min-height: 38px;
    }

    & tr td:nth-child(2)::before {
        display: none;
    }
}

.taskRadioBtn {
    // background-color: #f3f4f5;

    & .cssradio {
        flex-wrap: nowrap;
    }
}

// .manageTask_related_documents {
//     margin: 0 -1rem;
// }

.manageTask_related_documents .e-listview .e-list-item.e-hover {
    background-color: transparent;
}