.signature_outer_div {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--surface-neutral-base);
    padding: 1rem;
    margin: 0 -1rem 1rem;
}

.acknowledge_check_box {
    padding-top: 0.1875rem
}