.checklist_template_item_card_header {
    display: flex;
    align-items: center;
    justify-content: center;
    font: var(--body1-regular);
    color: var(--content-neutral-base);
    position: relative;
    z-index: 10;

    &::before {
        width: 100%;
        height: 1px;
        content: '';
        background-color: var(--stroke-neutral-base);
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
    z-index: 1;
}
    &>span {
        background-color: #fff;
    z-index: 10;
    padding: 0 0.25rem;
}
}