.care_shift{
    &_warning_message {
        background-color: var(--surface-state-warning-light) !important;
        color: var(--content-state-warning-base);
        font: var(--subtitle3-medium);
        border: 0 !important;
        &_div {
            display: flex;
            align-items: center;
        }
        &_icon {
             padding-right: 0.5rem;
             color: var(--content-state-warning-base);
line-height: inherit;
        }
        &_text {
        font: var(--subtitle3-medium);
        color: var(--content-state-warning-base);
    }
    }
&_log {
    &_header {
        align-items: center;
        justify-content: space-between;

        &_text {
            font: var(--subtitle2-medium);
            color: var(--content-neutral-dark);
        }
    }

    &_started_header_text {
        font: var(--subtitle2-medium);
        color: var(--content-state-warning-dark);
    }

    &_completed_header_text {
        font: var(--subtitle2-medium);
        color: var(--content-state-success-dark);
    }

    &_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.25rem 1rem;
        border-top: 1px solid var(--stroke-neutral-base);
    }
}
}