.care_task_section_footer {
    // position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    padding: 0.5rem 1rem;
    background-color: #f3f3f3;
    // margin-bottom: 1rem;
}
.dlg_add_activity {
    .sd-body.sd-body--static {
        // background-color: #fff;
        padding: 0 !important;
        padding-left: 0.5rem !important;
    }
    .sd-element--complex.sd-element--with-frame {
        padding: 0 0.75rem;
        box-shadow: unset;
        .sd-panel__content {
            padding-top: 0.75rem;
        }
    }
}

@media only screen and (min-width:576px) {
    .care_task_section_footer {
        padding: 1rem;
    }
}