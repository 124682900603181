.planner {
    &_timeline {
        & .timeline {
            &__li {
                padding: 1.25rem 0.5rem;

                &::before {
                    left: 1.5rem;
                    border-left: 1px dashed var(--stroke-neutral-dark);
                    background-color: transparent;
                }

                & .timeline__item .timeline__item__circle {
                    font-weight: 900;
                    font-size: 1rem;
                    color: var(--content-brand-primary-dark);
                    display: flex;
                    width: 2rem;
                    height: 2rem;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid var(--stroke-neutral-base);
                    border-radius: 50%;
                    background-color: #fff;

                    &_icon {
                        font-size: 1rem;
                        color: var(--content-brand-primary-dark);
                        // position: absolute;
                        // left: -3px;
                        // top: 4px;
                        background-color: var(--surface-neutral-light);
                    }

                    &::before {
                        border-color: transparent;
                        display: none;
                    }
                }


                &__completed {
                    & .timeline__item .timeline__item__circle {
                        // color: var(--content-state-success-dark);
                        // background-color: var(--surface-state-success-semi-dark);
                        position: relative;

                        &_icon {
                            color: var(--content-state-success-dark);
                        }

                        &__completed {
                            color: var(--content-state-success-dark);
                            position: absolute;
                            right: -4px;
                            bottom: -5px;
                            font-size: 0.875rem;

                            & i {

                                font-size: 0.875rem;
                                background-color: #fff;
                                color: inherit;
                                border-radius: 50%;
                            }
                        }
                    }

                }
            }

            &__date__container {
                width: 3rem;
                min-width: 3rem;
                max-width: 3rem;
            }

            &__time {
                width: 3rem;
                min-width: 3rem;
                max-width: 3rem;
            }
        }

        &_date_card {
            border-radius: 0.5rem;
            width: 3rem;
            min-width: 3rem;
            height: 3rem;
            min-height: 3rem;
            background-color: #fff;
            display: flex;
            flex-direction: column;

            &_month {
                // padding: 0.5rem 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font: var(--body3-semi-bold);
                background-color: var(--surface-state-danger-semi-dark);
                color: #fff;
                height: 100%;
                border-top-left-radius: 0.5rem;
                border-top-right-radius: 0.5rem;
                text-transform: uppercase;
            }

            &_date {
                // padding: 0.5rem 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font: var(--body2-semi-bold);
                height: 100%;
                color: var(--content-neutral-dark);
            }
        }

        & .note_card_content_transparentDiv {
            top: 110px;
        }

        & .e-listview .e-list-item.e-hover {
            background-color: transparent;
        }
    }

    &_header_filter_daropdown.e-btn.e-dropdown-btn {
        font: var(--subtitle2-medium) !important;
        color: var(--content-neutral-dark);
        padding: 0.5rem !important;
        max-width: 100%;
        overflow: hidden;
        margin-right: 0.5rem;
    }
}

@media only screen and (min-width: 768px) {
    .planner_timeline .timeline__li {
        padding: 0.5rem 1rem 0.5rem 1rem;

        &::before {
            left: 2rem;
        }
    }
}