.portal-header-text {
    line-height: normal;
    word-break: break-all;
    white-space: normal;
}

.portal-switchbtn-div {
    line-height: normal;
}

.portal-switchbtn-div button.e-btn.custom-button-sm {
    line-height: normal !important;
    height: auto !important;
    min-height: unset !important;
    word-break: break-all;
    white-space: normal;

    & .e-btn-icon.e-icon-left {
        width: auto;
    }
}

.rafUsersAvatar {
    display: flex;
    align-items: center;
    justify-content: center;

    &>div {
        margin-left: -0.75rem;

        &:first-child {
            margin-left: 0;
        }

        & .avatar-text {
            opacity: 1;
            border: 1px solid #fff;
        }
    }
}