.note_card {
    position: relative;
    overflow: hidden;

    &_color_badge {
        width: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        min-height: 1.5rem;
        border-radius: 0.25rem;
        background-color: var(--surface-neutral-base);
    }

    &_content {
        max-height: 100px;
        /* Replace with your fixed height */
        overflow: hidden;
        position: relative;
        display: block;

        &_transparentDiv {
            content: '';
            position: absolute;
            // bottom: 0;
            right: 0;
            width: 100%;
            height: 30px;
            background: linear-gradient(to bottom, transparent, white);
            top: 171px;
        }
    }

    & .e-switch-wrapper.e-switch-disabled:hover,
    & .e-switch-wrapper.e-switch-disabled {
        & .e-switch-inner {
            background-color: var(--surface-brand-primary-semi-dark);

            & .e-switch-on {
                background-color: var(--surface-brand-primary-semi-dark) !important;
            }

            & .e-switch-off {
                background-color: var(--surface-neutral-semi-dark) !important;
                border-color: var(--stroke-neutral-dark);
            }

            &.e-switch-active {
                border-color: var(--stroke-primary);
            }
        }

        & .e-switch-handle {
            background-color: #fff
        }
    }
}

.manage_client_notes_everyone_label {
    font: var(--body3-regular) !important;
}